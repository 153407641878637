/* You can add global styles to this file, and also import other style files */

@import 'variables';

html,
body {
 //height: 100%;
 width:100%;
 width: auto!important; overflow-x: hidden!important
}


body {
  margin: 0;
  padding: 0;
  display: block;
}

h1 {
  font-weight: 700;
  text-transform: uppercase;
  font-family: $heading-font-family;

  small {
    text-transform: lowercase;
  }
}

h2 {
  line-height: 24px;
  font-weight: 300;
}

h3 {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 800;
}

h5 {
  font-size: 12px;
  text-transform: uppercase;
}

p {
  font-size: 14px;
}

a {
  color: inherit;

  &:visited {
    color: inherit;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: $primary-color;
  }
}

ul {
  margin: 0;
  padding: 0;
}

.highlight {
  color: $primary-color;
}

*:focus {
  outline: none;
}

.divider {
  height: 30px;
}

.align-center {
  text-align: center;
}

.left {
  float: left;
}

.right {
  float: right;
}

hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 0;
  overflow: visible;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #cfcfcf;
}



.ant-btn-primary:active,
.ant-btn-primary:focus,
.ant-btn-primary:hover {
  background-color: #2d2929;
  border-color: #2d2929;
}

.ant-checkbox {
  display: table-cell;

  +span,
  &-wrapper+span {
    padding-left: 8px;
    padding-right: 8px;
    display: table-cell;
    vertical-align: top;
  }
}

.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
  border-bottom: 1px solid $primary-color;
}

.anticon-search {
  padding-right: 8px;
}

.ant-avatar-string {
  position: absolute;
  left: 40%;
}

.ant-card-head {
  background: #235064;
}


.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent;
  fill: rgba(255, 255, 255, 0.65);
}

.ant-menu-dark .ant-menu-item-selected {
  border-right: 0;
  color: #f1c400;
  fill: #f1c400;
}


.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  background-color: transparent;
  color: #f1c400;
  fill: #f1c400;
}

.ant-btn-circle, .ant-btn-circle-outline {
  font-size: 18px;}